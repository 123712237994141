import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from 'utils/browser';
import Layout from 'components/Layout';
import EventCardAnimation from 'components/EventCardAnimation';
import { SEO } from 'components/Seo';

export default function Logout() {
  if (!isBrowser) return null;
  const { logout } = useAuth0();
  logout({ returnTo: process.env.GATSBY_AUTH0_CALLBACK || process.env.AUTH0_CALLBACK });
  return (
    <Layout>
      <EventCardAnimation />
    </Layout>
  );
}

export const Head = () => {
  return <SEO title="Tutadoo | Logout" />;
};
